body {
    font-family: "Segoe UI",Roboto,
        "Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,
        "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

figure {
    margin: 0;
    margin-bottom: 1em;
}

figcaption {
    font-style: italic;
}

.post-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1em;

    div {
      flex: 1 1 0;
      
      &:first-child {
        padding-right: 0.5em;
      }
      
      &:last-child {
        padding-left: 0.5em;
        text-align: right;
      }
    }
}

.unsighted {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.inline-nav a {
    text-transform: lowercase;
}

.inline-nav div+div::before {
    content: "|";
    margin-left: 6px;
    margin-right: 6px;
}

.inline-nav {
    display: flex;
    flex-direction: row;
}

hr {
    border: 0;
    border-top: 1px solid black;
}

footer {
    color: gray;
}

footer a {
    color: gray;
}

footer a:hover {
    color: black;
}

.publication {
    margin-bottom: 1em;
}

.topmatter {
    margin-bottom: 1em;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tags a {
    display: block;
    color: black;
    background: #ccc;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    font-size: 9pt;
    font-weight: bold;
    text-decoration: none;
}

.tags a+a {
    margin-left: 0.5em;
}

.infoline {
    display: flex;
    flex-direction: row;
    color: gray;
    margin-top: 0.25em;
}

.topmatter h1  {
    margin-bottom: 0;
}

.infoline div+div {
    margin-left: 0.5em;
}

.post h1 a {
    color: black;
    text-decoration: none;
}

.post h1 a:hover {
    text-decoration: underline;
}

.fr {
    float: right;
    margin-left: 1em;
}

.fl {
    float: left;
}

pre {
    border-left: 2px solid #ccc;
    padding-left: 1em;
}

@import "tango.css"
